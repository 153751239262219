/**
 * JavaScript for Theme setting.
 */

// window size
export function window_size() {
  let windowWidth;
  let windowHeight;

  // ページ読み込み時
  window.addEventListener('DOMContentLoaded', function(){
    // ブラウザ内の表示域(スクロールバーを除く)
    windowWidth = document.documentElement.clientWidth;
    windowHeight = document.documentElement.clientHeight;
    document.documentElement.style.setProperty('--window-width', windowWidth);
    document.documentElement.style.setProperty('--window-height', windowHeight);
    document.documentElement.style.setProperty('--window-width-px', (windowWidth) + 'px');
    document.documentElement.style.setProperty('--window-height-px', (windowHeight) + 'px');
  });

  // ウィンドウのサイズ変更イベントの設定
  window.addEventListener('resize', function(){
    windowWidth = document.documentElement.clientWidth;
    windowHeight = document.documentElement.clientHeight;
    document.documentElement.style.setProperty('--window-width', windowWidth);
    document.documentElement.style.setProperty('--window-height', windowHeight);
    document.documentElement.style.setProperty('--window-width-px', (windowWidth) + 'px');
    document.documentElement.style.setProperty('--window-height-px', (windowHeight) + 'px');
  });
}
