/**
 * humburger
 */
import "jquery";
export function humburger_menu() {
	jQuery( function($) {
		$('.hamburger-button').click(function() {
			$(this).toggleClass('is-open');
			$('body').toggleClass('is-locked');
			$('#global-menu-sp').toggleClass( 'is-open' );
			$('.gray-layer').toggleClass( 'is-active' );
		});

		$('.toggle').click(function () {
				$(this).next('.sub-menu').slideToggle();
				$(this).toggleClass('is-open');
		});

	} );
}
