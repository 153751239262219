/**
 * sidemenu
 */
import "jquery";
export function sidemenu_toggle() {
	jQuery( function($) {
		$('ul.area ul span').click(function() {
			$(this).next('ul').slideToggle('fast');
		});
	});
}
