/**
 * viewport switcher
 */
import "jquery";
export function viewport_switcher() {
	jQuery( function($) {
		// $("head").prepend("<meta name='viewport' content="
		// +($.cookie("switchScreen") == 1 ?
		// "'width=1060'" :
		// "'width=device-width, initial-scale=1, maximum-scale=1'")
		// +" />");

		$("#btnPC, #btnSP").click(function() {
			$.cookie("switchScreen", $(this).attr("id") == "btnPC" ? 1 : 0 , { path: '/', secure:true });
			location.reload();
			return false;
		});
		if ($.cookie("switchScreen") == 1 ) {
			$("meta[name = 'viewport']").attr('content', 'width=1060');
		}
		if ($.cookie("switchScreen") == 0 ) {
			$("meta[name = 'viewport']").attr('content', 'width=device-width, initial-scale=1, maximum-scale=1');
		}
	});
}
