/**
 * slick
 */
import "jquery";
import "slick-carousel";
export function slick() {
	jQuery(function($){
			$( '.p-slider' ).slick({
					fade: true,
					autoplay: true,
					autoplaySpeed: 3000,
					speed: 2000,
					arrows: false,
					dots: true,
					prevArrow: '<button type="button" class="slick-prev">Previous</button>',
					nextArrow: '<button type=”button” class="slick-next">Next</button>'
			});
	});
}
